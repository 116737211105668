import React from 'react';
import { Grid } from '@mui/material';

import {
  HashRouter,
  Routes,
  Route
} from "react-router-dom";

import Header from './components/common/header';
import Landing from './components/landing';
import Footer from './components/common/footer';

import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      test: null
    }
  }

  redirect (id) {
    let offsetTop  = document.getElementById(id).offsetTop;
    window.scrollTo({
        top: offsetTop-20, 
        behavior: "smooth"
    });
  }

  render () {
    return (
      <Grid container>
        <HashRouter>
          <Header redirect={this.redirect} />

          <Routes>
            <Route path="/" element={<Landing redirect={this.redirect} />} />
          </Routes>

          <Footer redirect={this.redirect} />
        </HashRouter>
      </Grid>
    );
  }
}

export default App;
