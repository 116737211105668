/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Button, Grid, TextField
} from '@mui/material';

import { ArrowForward } from '@mui/icons-material';

import './../App.css';

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: '',
      telefono: '',
      correo: '',
      mensaje: '',
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { name } = e.target;
    let { value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  render () {
    return (
      <Grid container>
        <Grid item xs={12} id="start" sx={{ display: 'flex' }} alignItems="center">
          <Grid container>
            <Grid item xs={12} sm={6}>
              <h1>RAHER</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nuncodio in et, lectus sit lorem id integer.</p>

              <Button variant="contained" className="button-yellow" onClick={() => { this.props.redirect('us') }}>Conocenos</Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} id="us">
          <Grid container>
            <Grid item xs={12}>
              <h2>Nosotros</h2>
              <p>Somos una empresa ... lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis scelerisque at quam congue posuere libero in sit quam. Consequat, scelerisque non tincidunt sit lectus senectus</p>
            </Grid>
            
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sx={{ display: 'flex', height: '200px', overflow: 'hidden', margin: '20px 0px' }} justifyContent="center" alignItems="center">
                  <img src={"assets/images/background-test.jpg"} alt="Us" style={{ maxWidth: '100%' }} />
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <h2>Misión</h2>
                <p>Queremos convertirnos en algo indispensable para nuestros clientes al ofrecer nuestros servicios y productos para alcanzar sus propósitos.</p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h2>Visión</h2>
                <p>Dar nuestros mejores servicios para que tengan la mejor experiencia día a día y convertirnos en su mano derecha.</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} id="info" sx={{ display: 'flex' }} justifyContent="center" alignItems="center">
          <Grid container>
            <Grid item xs={12} className="white-box">
              <p className="text-center">
                Responsabilidad, Seguridad, Compromiso.
              </p>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} id="services">
          <Grid container spacing={12}>
            <Grid item xs={12} sm={6}>
              <h2>Servicios</h2>
              <div>
                <img src="assets/images/background-test.jpg" alt="Service 1" />
                <h3>Capacitación</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus, fermentum amet faucibus sed id nisi lectus at.</p>
              </div>
              <div>
                <img src="assets/images/background-test.jpg" alt="Service 2" />
                <h3>Servicios de consultoría</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus, fermentum amet faucibus sed id nisi lectus at.</p>
              </div>
            </Grid>
            
            <Grid item xs={12} sm={6} className="padding-top-0">
              <div>
                <img className="mini" src="assets/images/background-test.jpg" alt="Service 3" />
                <h3>Consultoría</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus, fermentum amet faucibus sed id nisi lectus at.</p>
              </div>
              <div>
                <img className="mini" src="assets/images/background-test.jpg" alt="Service 4" />
                <h3>Asesorías en sistemas de calidad</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus, fermentum amet faucibus sed id nisi lectus at.</p>
              </div>
              <div>
                <img className="mini" src="assets/images/background-test.jpg" alt="Service 2" />
                <h3>Comercialización compra y Venta de equipos de medición y prueba.</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus, fermentum amet faucibus sed id nisi lectus at.</p>
              </div>
              <Grid container alignItems="center">
                <a href="#">
                  Descubre más sobre nuestros servicios
                </a>
                <ArrowForward />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} id="contact">
          <Grid container>
            <Grid item xs={12} sm={4} sx={{ display: 'flex', overflow: 'hidden' }} justifyContent="center">
              <img src="assets/images/background-test.jpg" alt="Contact Us" />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={12} sm={6} sx={{ padding: '20px 60px', display: 'flex', maxHeigh: '80vh' }} direction="column" justifyContent="center">
              <h2>Ponte en Contacto</h2>
              <TextField fullWidth name="nombre" value={this.state.nombre} onChange={this.handleChange} size="small" margin="normal" label="Nombre *" variant="filled" />
              <TextField fullWidth name="telefono" value={this.state.telefono} onChange={this.handleChange} size="small" margin="normal" label="Teléfono *" variant="filled" />
              <TextField fullWidth name="correo" value={this.state.correo} onChange={this.handleChange} size="small" margin="normal" label="Correo Electrónico *" variant="filled" />
              <TextField fullWidth name="mensaje" value={this.state.mensaje} onChange={this.handleChange} size="small" margin="normal" multiline label="Escribe tu mensaje aquí" maxRows={4} variant="filled" />

              <Button variant="contained" className="button-black" onClick={() => { this.props.redirect('us') }}>ENVIAR</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default Landing;