/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Grid
} from '@mui/material';

import './../../App.css'

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      test: null
    }
  }

  redirect (id) {
    // this.props.redirect(id);
  }

  render() {
    return (
      <Grid container id="footer">
        <Grid item xs={12} sm={6}>
          <Grid container>
            <Grid item xs={12}>
              <img src="assets/logos/1.png" className="img-logo" alt="Logo" height={70} />
            </Grid>
            <Grid item xs={12}>
              <a href="#" className="a-img">
                <img src="assets/icons/WhatsApp.png" alt="Whatsapp" />
              </a>
              <a href="#" className="a-img">
                <img src="assets/icons/WhatsApp.png" alt="Whatsapp" />
              </a>
              <a href="#" className="a-img">
                <img src="assets/icons/WhatsApp.png" alt="Whatsapp" />
              </a>
            </Grid>
            <Grid item xs={12} className="hide-sm">
              <a className="a-text" onClick={() => { this.props.redirect('start')}}>Inicio</a>
              <a className="a-text" onClick={() => { this.props.redirect('us')}}>Nosotros</a>
              <a className="a-text" onClick={() => { this.props.redirect('services')}}>Servicios</a>
              <a className="a-text" onClick={() => { this.props.redirect('contact')}}>Contactanos</a>
            </Grid>
            <Grid item xs={12}>
              <p>© 2022 RAHER. All rights reserved</p>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} className="hide-sm" sx={{ display: 'flex', padding: '10px !important' }} direction="column" justifyContent="center" alignItems="flex-end">
          <h4>Contacto</h4>
          <div style={{ display: 'flex', alignItems: 'center', direction: 'column', marginTop: '10px' }}>
            <img src="assets/icons/WhatsApp.png" alt="Whatsapp"/>
            <a href="https://wa.me/528110758977" target="_blank" rel="noopener noreferrer">8110758977</a>
          </div>
          <div style={{ marginTop: '10px' }}>
            <a href="mailto:raher@raher.com.mx" target="_blank" rel="noopener noreferrer">raher@hotmail.com</a>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default Footer;