/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Button, Grid, Box,  AppBar, Toolbar
} from '@mui/material';

import './../../App.css'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      test: null
    }
  }

  render() {
    return (
      <Grid container>
        <Grid item xs={12} className="black-header">
          <Grid container alignItems="center">
            <Grid item xs={12} sm={6} className="flex-center">
              <img src="assets/logos/1.png" alt="Logo" height="75px"/>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid container justifyContent="flex-end" alignItems="center" className="flex-column flex-center">
                <Grid item sx={{ display: 'flex', marginRight: '20px' }} alignItems="center">
                  <img src="assets/icons/WhatsApp.png" alt="Whatsapp"/>
                  <a href="https://wa.me/528110758977" target="_blank" rel="noopener noreferrer">8110758977</a>
                </Grid>
                <Grid item>
                  <Button variant="contained" className="button-yellow" onClick={() => { this.props.redirect('contact') }}>COTIZAR</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="hide-sm">
          <Box>
            <AppBar component="nav" position="static" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Toolbar className="flex-column">
                <a href="#" onClick={() => { this.props.redirect('start') }}>Inicio</a>
                <a href="#" onClick={() => { this.props.redirect('us') }}>Nosotros</a>
                <a href="#" onClick={() => { this.props.redirect('services') }}>Servicios</a>
                <a href="#" onClick={() => { this.props.redirect('contact') }}>Contactanos</a>
              </Toolbar>
            </AppBar>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default Header;